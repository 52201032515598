import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useDashboard } from "../../../hooks/Services/useDashboard";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, CircularProgress } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
// components
import { useChart } from "../../../components/chart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

const StyledSpinnerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppCurrentVisits({
  title,
  subheader,
  chartColors,
  chartData,
  loading,
  ...other
}) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    formatter: function (val, opts) {
      return val;
    },
    tooltip: {
      fillSeriesColor: true,
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      {loading ? (
        <StyledSpinnerWrapper>
          <CircularProgress />
        </StyledSpinnerWrapper>
      ) : (
        <StyledChartWrapper dir="ltr">
          <ReactApexChart
            type="pie"
            series={chartSeries}
            options={chartOptions}
            height={280}
          />
        </StyledChartWrapper>
      )}
    </Card>
  );
}
