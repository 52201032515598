import { Box, Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import CardKanban from "./CardKanban";
import useResponsive from "../../hooks/useResponsive";

// status 0 - pendente atribuir para vistoriador (Pendente Direcionar Vistoriador)
// status 1 - foi atribuido a um vistoriador (Com vistoriador)
// status 3 - cancelado
// status 4 - foi respondido pelo vistoriador (Regulação de danos)
// status 5 - respondido para o cliente (Validação)

const status = [
  {
    status: 0,
    title: "Pendente Direcionar",
  },
  {
    status: 1,
    title: "Com vistoriador",
  },
  {
    status: 4,
    title: "Regulação de danos",
  },
  {
    status: 5,
    title: "Validação",
  },
  {
    status: 3,
    title: "Cancelados",
  },
];

const Kanban = ({ datas }) => {
  const [columns, setColumns] = useState([]);
  const [statusNum, setStatusNum] = useState(0);

  const [columnHeigth, setColumnHeigth] = useState(0);

  const isMobile = useResponsive("down", "sm");
  const isTablet = useResponsive("between", "sm", "md");

  // const { statusParam } = useParams();

  useEffect(() => {
    if (datas) {
      setColumns(() => {
        const newArray = status?.map((item) => {
          return {
            uuid: uuidv4(),
            title: item.title,
            status: item.status,
            ...datas,
            items: datas
              ?.filter((itemNew) => itemNew.status === item.status)
              ?.map((itemN) => {
                return {
                  uuid: uuidv4(),
                  title: item.title,
                  ...itemN,
                };
              }),
          };
        });

        return newArray;
      });

      setStatusNum(status?.length || 4);
    }
  }, [datas]);

  useEffect(() => {
    const boxes = document.querySelectorAll("#column");

    let newHeight = 0;

    boxes.forEach((box) => {
      const boxHeigth = box.getBoundingClientRect().height;

      if (newHeight < boxHeigth) {
        newHeight = boxHeigth;
      }
    });

    setColumnHeigth(newHeight);
  }, [columns]);

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    if (
      source?.droppableId === destination?.droppableId &&
      source?.index === destination?.index
    )
      return;

    const getItemUuid = draggableId;

    const getDragColumnUuid = source?.droppableId;
    const getDragColumnIndex = source?.index;

    const getColumnDestinationUuid = destination?.droppableId;
    const getColumnDestinationIndex = destination?.index;

    setColumns((prevState) => {
      const newArray = [...prevState];

      const getDragItemObj = newArray
        ?.find((column) =>
          column.items.find((item) => item.uuid === getItemUuid)
        )
        ?.items.find((item) => item.uuid === getItemUuid);

      newArray.map((column) => {
        if (column.uuid === getDragColumnUuid) {
          return {
            ...column,
            items: column.items.splice(getDragColumnIndex, 1),
          };
        }

        return column;
      });

      newArray.map((column) => {
        if (column.uuid === getColumnDestinationUuid) {
          return {
            ...column,
            items: column.items.splice(getColumnDestinationIndex, 0, {
              ...getDragItemObj,
              title: column.title,
              status: column.status,
            }),
          };
        }

        return column;
      });

      return newArray;
    });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Grid
        container
        sx={{
          padding: "0 0.5rem",
          display: "flex",
          flexDirection: isMobile || isTablet ? "column" : "row",
          overflowX: isMobile || isTablet ? "auto" : "none",
        }}
      >
        {columns?.map((column, columnIndex) => (
          <Grid
            item
            xs={isMobile || isTablet ? 12 : 12 / statusNum}
            key={column?.uuid}
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignItems: "center",
              marginTop: isMobile || isTablet ? "50px" : "0px",
            }}
          >
            <Chip
              style={{
                maxWidth: "fit-content",
                fontSize: "1rem",
              }}
              label={column.title}
              variant="outlined"
              color={
                column.status === 0
                  ? "primary"
                  : column.status === 1
                  ? "warning"
                  : column.status === 4
                  ? "success"
                  : column.status === 5
                  ? "info"
                  : "error"
              }
            />

            <Droppable
              key={column.uuid}
              droppableId={column?.uuid?.toString()}
              ignoreContainerClipping
            >
              {(provided) => {
                return (
                  <Box
                    id="column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: isMobile || isTablet ? "row" : "column",
                      padding: "0.5rem",
                      gap: "0.5rem",
                      borderRight:
                        isMobile || isTablet
                          ? "none"
                          : columnIndex !== columns?.length - 1
                          ? "3px solid #ccc"
                          : "none",
                      minHeight:
                        isMobile || isTablet ? "auto" : `${columnHeigth}px`,
                    }}
                  >
                    {column?.items
                      // ?.filter(
                      //   (item) =>
                      //     statusParam === "999" ||
                      //     item.status.toString() === statusParam.toString()
                      // )
                      ?.map((item, index) => {
                        return (
                          <Draggable
                            key={item.uuid}
                            draggableId={item.uuid}
                            index={index}
                            isDragDisabled
                          >
                            {(provided) => {
                              return (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <CardKanban
                                    infoCards={item}
                                    setColumnHeigth={setColumnHeigth}
                                  />
                                </Box>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </Box>
                );
              }}
            </Droppable>
          </Grid>
        ))}
      </Grid>
    </DragDropContext>
  );
};

export default Kanban;
