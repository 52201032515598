import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useRef, useState } from "react";
import { object, string, mixed } from "yup";
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  Modal,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { MuiFileInput } from "mui-file-input";
import { useLocation } from "react-router-dom";

import { useSurveys } from "../hooks/Services/useSurveys";
import Scrollbar from "../components/scrollbar";
import AlertSnackbar from "../components/alert/AlertSnackbar";
import ReplySurveyModal from "../components/replySurveyModal";
import { SnackBarContext } from "../contexts/SnackBarContext";
// ----------------------------------------------------------------------

export default function ReplySurvey() {
  const { replySurvey, message, isLoading, setMessage } = useSurveys();
  const location = useLocation();
  const props = location.state;

  const [formValues, setFormValues] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const { setSnackBarMessage } = useContext(SnackBarContext);

  useEffect(() => {
    setSnackBarMessage({
      message:
        "Antes de responder, verifique se você preencheu os dados do chassi e placa!",
      severity: "warning",
    });
  }, []);

  const Schema = object().shape({
    formValues: object().shape({
      labor: string().required("Mão de obra é necessária"),
      pieces: string().required("Total em peças necessário"),
      additional: string("Observações adicionais são necessárias"),
    }),
    fileCilia: mixed().required("Documento Cilia é necessário"),
  });

  const handleAlert = () => {
    setShowAlert(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let newValue = value;
    if (name === "labor" || name === "pieces") {
      newValue = newValue.replace(/\D/g, "");
      newValue = (newValue / 100).toFixed(2);
      newValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(newValue);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const [fileCilia, setFileCilia] = useState(null);
  const handleInputChangeCilia = (file) => {
    setFileCilia(file);
  };

  useEffect(() => {
    if (message.message !== "") {
      setShowAlert(true);
    }
  }, [message]);

  const handleSubmit = async (values) => {
    Schema.validate(values, { abortEarly: false })
      .then(async () => {
        values.formValues.labor = parseFloat(
          values.formValues.labor.replace(/\D/g, "") / 100
        ).toFixed(2);
        values.formValues.pieces = parseFloat(
          values.formValues.pieces.replace(/\D/g, "") / 100
        ).toFixed(2);
        replySurvey(values);
      })
      .catch((error) => {
        setMessage({ message: error.errors[0], status: 500, show: true });
      });
  };

  return (
    <>
      <Helmet>
        <title>Responder Vistoria</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Responder vistoria
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6">Valores do serviço</Typography>
              <Box className="d-flex mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      value={formValues.labor ?? ""}
                      onChange={handleChange}
                      id="outlined-basic"
                      label="Total mão de obra"
                      variant="outlined"
                      className="w-100"
                      name="labor"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={formValues.pieces ?? ""}
                      onChange={handleChange}
                      id="outlined-basic"
                      label="Total peças"
                      variant="outlined"
                      className="w-100"
                      name="pieces"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={formValues.additional ?? ""}
                      onChange={handleChange}
                      id="outlined-multiline-static"
                      name="additional"
                      label="Observações adicionais..."
                      multiline
                      rows={4}
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiFileInput
                      className="w-100"
                      value={fileCilia}
                      onChange={handleInputChangeCilia}
                      placeholder={"Documento Cilia"}
                      name="cilia"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LoadingButton
                      onClick={handleOpenModal}
                      className="w-100 mb-1 mt-3"
                      variant="outlined"
                      //   loading={isLoading}
                    >
                      Devolver para vistoriador
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6}>
                    <LoadingButton
                      onClick={() =>
                        handleSubmit({
                          formValues,
                          fileCilia,
                          survey: props.id,
                        })
                      }
                      className="w-100 mb-1 mt-3"
                      variant="outlined"
                      loading={isLoading}
                    >
                      Finalizar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Scrollbar>
        </Card>
      </Container>

      <ReplySurveyModal
        open={openModal}
        handleClose={handleCloseModal}
        id={props.id}
      />

      <AlertSnackbar
        openAlert={showAlert}
        duration={2500}
        status={message.status}
        message={message.message}
        onClose={handleAlert}
      />
    </>
  );
}
