import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();

    const updateUserName = useCallback(() => {
        const token = localStorage.getItem('Token_Kinto_Btck');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUserName(decoded.name);
                setUserId(decoded.id);
            } catch {
                navigate('/login');
            }
        }
    }, [navigate]);

    useEffect(() => {
        updateUserName();
    }, [updateUserName]);
    
    useEffect(() => {
        window.addEventListener('tokenUpdated', updateUserName);
        return () => {
            window.removeEventListener('tokenUpdated', updateUserName);
        };
    }, [updateUserName]);

    return (
        <AppContext.Provider value={{ userName }}>
            {children}
        </AppContext.Provider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired
}