import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
import { useDashboard } from "../hooks/Services/useDashboard";
import { useState, useEffect } from "react";
import { AppContext } from "../hooks/AppContext";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { userName } = useContext(AppContext);
  const { result, isLoading, fetchData } = useDashboard();
  const [dashboard, setDashboard] = useState({});

  const theme = useTheme();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (result !== null && result !== undefined) {
      setDashboard(result.data);
    }
  }, [result]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, {userName.split(" ")[0]}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            {/* Comentário: Loading spinner */}
            <AppWidgetSummary
              title="Finalizados"
              total={dashboard.finish}
              color="success"
              icon={"gis:flag-finish-b-o"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* Comentário: Loading spinner */}
            <AppWidgetSummary
              title="Respondidos"
              total={dashboard.answered}
              color="primary"
              icon={"eos-icons:system-ok"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* Comentário: Loading spinner */}
            <AppWidgetSummary
              title="Pendentes"
              total={dashboard.pending}
              color="warning"
              icon={"material-symbols:pending"}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              title="SLA"
              chartData={[
                {
                  label: "Atribuição",
                  value: Number(dashboard.slaStatus0_1) || 0,
                },
                {
                  label: "Resposta",
                  value: Number(dashboard.slaStatus1_4) || 0,
                },
                {
                  label: "Orçamento",
                  value: 2.2 || 0,
                },
                // { label: 'Rejeitados', value: dashboard.rejected || 0 },
                // { label: 'Finalizados', value: dashboard.finished || 0 },
              ]}
              chartColors={[
                theme.palette.warning.main,
                theme.palette.success.main,
                // theme.palette.error.main,
                // theme.palette.info.main,
              ]}
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              title="Resumo"
              chartData={[
                { label: "Solicitaçoes", value: dashboard.requests || 0 },
                { label: "Respondidos", value: dashboard.answered || 0 },
                // { label: 'Rejeitados', value: dashboard.rejected || 0 },
                // { label: 'Finalizados', value: dashboard.finished || 0 },
              ]}
              chartColors={[
                theme.palette.warning.main,
                theme.palette.success.main,
                // theme.palette.error.main,
                // theme.palette.info.main,
              ]}
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates
              title="Solicitações"
              // subheader="(+43%) que ano passado"
              chartData={
                dashboard.cars || [
                  { label: "VALOR 1", value: 50 },
                  { label: "PARAMETRO 2", value: 120 },
                  { label: "VALOR 3", value: 30 },
                  { label: "PARAMETRO 4", value: 80 },
                  { label: "VALOR 5", value: 90 },
                  { label: "PARAMETRO 6", value: 100 },
                  { label: "VALOR 7", value: 58 },
                ]
              }
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="Notificações"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTasks title="Tarefas" list={[]} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
