import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AppProvider } from "./hooks/AppContext";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import "bootstrap/dist/css/bootstrap.min.css";
import SnackBar from "./components/snackbar";
import ContextProviders from "./contexts";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <BrowserRouter>
      <ContextProviders>
        <HelmetProvider>
          <AppProvider>
            <ThemeProvider>
              <SnackBar />
              <ScrollToTop />
              <StyledChart />
              <Router />
            </ThemeProvider>
          </AppProvider>
        </HelmetProvider>
      </ContextProviders>
    </BrowserRouter>
  );
}
