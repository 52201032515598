import { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TextField,
} from "@mui/material";
// component
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginRight: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  filterValues: PropTypes.array,
  filterValue: PropTypes.string,
  onFilterValue: PropTypes.func,
  searchFunction: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  searchFunction,
  resetFilters,
  searchFields,
}) {
  const status = [
    { label: "Pendente", value: 0, id: 1 },
    { label: "Aceito", value: 1, id: 2 },
    { label: "Rejeitado", value: 2, id: 3 },
  ];

  useEffect(() => {
    searchFunction(
      searchFields.map((field) => ({
        [field.apiField]: field.inputValue,
      }))
    );
  }, [JSON.stringify(searchFields), searchFunction]);

  return (
    <>
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            color: "primary.main",
            bgcolor: "primary.lighter",
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} {numSelected === 1 ? "selecionado" : "selecionados"}
          </Typography>
        ) : (
          searchFields.map((field, index) =>
            field.type === "text" ? (
              <StyledSearch
                key={index}
                onChange={field.onChange}
                value={field.inputValue}
                placeholder={field.title}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
              />
            ) : (
              <Autocomplete
                // disablePortal
                key={index}
                className="w-25 me-4"
                disableClearable
                freeSolo
                forcePopupIcon
                id="combo-box-demo"
                options={status}
                value={(field.inputValue && field.inputValue.label) || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Status" />
                )}
                onChange={(event, option) => {
                  const selectedValue = option;
                  field.onChange(selectedValue);
                }}
              />
            )
          )
        )}

        {numSelected > 0 ? (
          <Tooltip title="Deletar">
            <IconButton>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        ) : (
          <div className="ms-auto">
            <Tooltip title="Resetar filtros">
              <IconButton onClick={resetFilters}>
                <Iconify icon="carbon:reset" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </StyledRoot>
    </>
  );
}
