import { Box, Typography } from "@mui/material";

const ErrorMessageForm = ({ children }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "14px",
          paddingLeft: "16px",
          color: "#ff4943",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default ErrorMessageForm;
