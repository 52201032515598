import { Box, Grid, Modal, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ErrorMessageForm from "../errorMessageForm";
import { useSurveys } from "../..//hooks/Services/useSurveys";

const style = {
  position: "absolute",
  borderRadius: "20px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ReplySurveyModal = ({ open = false, handleClose, id }) => {
  const { returnSurvey } = useSurveys();

  const SchemaModal = Yup.object().shape({
    description: Yup.string().required("Descrição é necessária."),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: SchemaModal,
    onSubmit: async (values, { resetForm }) => {
      const returnData = {
        message: values.description,
        id: id,
      };

      await returnSurvey(returnData);

      resetForm();
      handleClose();
    },
  });

  const { isSubmitting, getFieldProps, errors, touched, resetForm } = formik;

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          resetForm();
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FormikProvider value={formik}>
          <Form>
            <Box sx={style}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextareaAutosize
                    minRows={5}
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    placeholder="Dgite sua mensagem ..."
                    helperText={Boolean(
                      touched.description && errors.description
                    )}
                    style={{
                      width: "100%",
                      border: "0.5px solid #2f2e2e",
                      borderRadius: "4px",
                      resize: "none",
                      padding: "1rem",
                    }}
                  />
                </Grid>

                {touched.description &&
                  errors.description &&
                  touched.description &&
                  errors.description && (
                    <Grid item xs={12}>
                      <ErrorMessageForm>{errors.description}</ErrorMessageForm>
                    </Grid>
                  )}
                <Grid item xs={6}>
                  <LoadingButton
                    type="submit"
                    className="w-100 mb-1 mt-3"
                    variant="outlined"
                    loading={isSubmitting}
                  >
                    Enviar
                  </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton
                    onClick={() => {
                      resetForm();
                      handleClose();
                    }}
                    className="w-100 mb-1 mt-3"
                    variant="contained"
                  >
                    Voltar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Form>
        </FormikProvider>
      </Modal>
    </div>
  );
};

export default ReplySurveyModal;
