import { useCallback, useState } from 'react';
import api from '../api';

export function useUsers() {
    const [result, setResult] = useState(null);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const getSurveyor = useCallback(async () => {
        setIsLoading(true);

        try {
            const { data } = await api.get(`/btck/list/surveyor/0/200`);

            if (data) {
                setResult(data);
            }            
        } catch {
            setResult({});
            setStatus(500);
            setMessage('Sem conexão com o servidor!');
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { getSurveyor, result, isLoading, message, status, setMessage };
}