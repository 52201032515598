import { useCallback, useState } from 'react';
import api from '../api';

export function useLogin() {
    const [result, setResult] = useState(null);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loginUser = useCallback(async (userData) => {
        setIsLoading(true);

        try {
            const { data } = await api.post(`/login/user`, { ...userData, permission: 'BTCK'});

            if (data) {
                setResult(data);
                setMessage(data.message);
                setStatus(data.status);
                localStorage.setItem('Token_Kinto_Btck', data.token)
            } else {
                setResult({});
                setMessage('Credenciais inválidas ou usuário inativo!');
                setStatus(400);
            }             
        } catch {
            setResult({});
            setStatus(500);
            setMessage('Sem conexão com o servidor!');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const resetPassword = useCallback(async (userEmail) => {
        setIsLoading(true);

        const params = {
            email: userEmail
        }

        try {
            const { data } = await api.post(`/login/recovery`, params);

            if (data) {
                setMessage({ message: data.message, status: 200, show: true });
            } else {
                setMessage({ message: 'Erro ao solicitar nova senha', status: 500, show: true });
            }             
        } catch {
            setMessage({ message: 'Erro de conexão com o servidor', status: 500, show: true });
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { loginUser, resetPassword, result, isLoading, message, status, setMessage };
}